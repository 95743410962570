.input-text {
	height: 42px;
	border-radius: 4px;
	border: 1px solid #b2b4b8;
	color: #11192b;
	font-size: 16px;
	font-weight: 400;
	line-height: 18px;
	padding: 8px;
	width: 100%;

	@include hover {
		border-color: #458eca; }


	&:focus {
		border-color: #458eca; } }

.label-text {
	color: #b2b4b8;
	font-size: 14px;
	font-weight: 400;
	line-height: 18px;
	letter-spacing: normal;
	margin-bottom: 4px; }

label {
	display: block; }
