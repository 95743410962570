.row {
	display: flex;
	flex-wrap: wrap;

	$gap: 30px;

	width: calc(100% + #{$gap});
	margin-left: -$gap / 2;
	margin-right: -$gap / 2;

	& > .col {
		margin: $gap / 2;
		max-width: calc(100% - #{$gap}); }

	&.cols-lg-2 {
		& > .col {
			width: calc(50% - #{$gap}); }

		@include max(991) {
			& > .col {
				width: calc(100% - #{$gap}); } } }

	&.cols-2 {
		& > .col {
			width: calc(50% - #{$gap}); }

		@include max(719) {
			& > .col {
				width: calc(100% - #{$gap}); } } }

	&.cols-3 {
		& > .col {
			width: calc(33.33% - #{$gap}); }

		@include max(719) {
			& > .col {
				width: calc(100% - #{$gap}); } } }

	&.cols-4 {
		& > .col {
			width: calc(25% - #{$gap}); }

		@include max(1199) {
			& > .col {
				width: calc(50% - #{$gap}); } }


		@include max(719) {
			& > .col {
				width: calc(100% - #{$gap}); } } }

	.col-grow {
		flex-grow: 1; }

	.col-fix {
		flex-grow: 0;
		flex-shrink: 0; }

	&.nowrap {
		flex-wrap: nowrap; }

	&.row-mobile-reverse {
		@include max(719) {
			flex-direction: column-reverse; } } }

.align-items-center {
	align-items: center; }

.align-items-start {
	align-items: flex-start; }

.align-items-end {
	align-items: flex-end; }

$space: 15px;
$spaces: ("20": 20px, "0": 0px, "15": $space, "30": $space * 2, "45": $space * 3, "60": $space * 4);

@each $z in [-5, -4, -3, -2, -1, 0, 1, 2, 3, 4, 5] {
	.z-index-#{$z} {
		z-index: $z; } }

@mixin indents($name, $val) {
	.p-#{$name} {
		padding: $val !important; }

	.pt-#{$name} {
		padding-top: $val !important; }

	.pb-#{$name} {
		padding-bottom: $val !important; }

	.pe-#{$name} {
		padding-right: $val !important; }

	.ps-#{$name} {
		padding-left: $val !important; }

	.py-#{$name} {
		padding-top: $val !important;
		padding-bottom: $val !important; }

	.px-#{$name} {
		padding-left: $val !important;
		padding-right: $val !important; }

	.m-#{$name} {
		margin: $val !important; }

	.mt-#{$name} {
		margin-top: $val !important; }

	.mb-#{$name} {
		margin-bottom: $val !important; }

	.me-#{$name} {
		margin-right: $val !important; }

	.ms-#{$name} {
		margin-left: $val !important; }

	.my-#{$name} {
		margin-top: $val !important;
		margin-bottom: $val !important; }

	.mx-#{$name} {
		margin-left: $val !important;
		margin-right: $val !important; } }

// indents
@each $k in [1, -1] {
	@each $media, $screen in ("": 0, "md": 720, "lg": 1200) {
		@each $name, $val in $spaces {
			$val: $val * $k;

			@if $k < 0 {
				$name: "-" + $name; }

			@if $screen != 0 {
				$name: $media + "-" + $name;

				@include min($screen) {
					@include indents($name, $val); } }
			@else {
				@include indents($name, $val); } } } }

// @each $name, $val in $spaces
// 	indents($name, $val)
