.row {
  display: flex;
  flex-wrap: wrap;
  width: calc(100% + 30px);
  margin-left: -15px;
  margin-right: -15px;
}

.row > .col {
  margin: 15px;
  max-width: calc(100% - 30px);
}

.row.cols-lg-2 > .col {
  width: calc(50% - 30px);
}

@media (max-width: 991px) {
  .row.cols-lg-2 > .col {
    width: calc(100% - 30px);
  }
}

.row.cols-2 > .col {
  width: calc(50% - 30px);
}

@media (max-width: 719px) {
  .row.cols-2 > .col {
    width: calc(100% - 30px);
  }
}

.row.cols-3 > .col {
  width: calc(33.33% - 30px);
}

@media (max-width: 719px) {
  .row.cols-3 > .col {
    width: calc(100% - 30px);
  }
}

.row.cols-4 > .col {
  width: calc(25% - 30px);
}

@media (max-width: 1199px) {
  .row.cols-4 > .col {
    width: calc(50% - 30px);
  }
}

@media (max-width: 719px) {
  .row.cols-4 > .col {
    width: calc(100% - 30px);
  }
}

.row .col-grow {
  flex-grow: 1;
}

.row .col-fix {
  flex-grow: 0;
  flex-shrink: 0;
}

.row.nowrap {
  flex-wrap: nowrap;
}

@media (max-width: 719px) {
  .row.row-mobile-reverse {
    flex-direction: column-reverse;
  }
}

.align-items-center {
  align-items: center;
}

.align-items-start {
  align-items: flex-start;
}

.align-items-end {
  align-items: flex-end;
}

.z-index--5 {
  z-index: -5;
}

.z-index--4 {
  z-index: -4;
}

.z-index--3 {
  z-index: -3;
}

.z-index--2 {
  z-index: -2;
}

.z-index--1 {
  z-index: -1;
}

.z-index-0 {
  z-index: 0;
}

.z-index-1 {
  z-index: 1;
}

.z-index-2 {
  z-index: 2;
}

.z-index-3 {
  z-index: 3;
}

.z-index-4 {
  z-index: 4;
}

.z-index-5 {
  z-index: 5;
}

.p-20 {
  padding: 20px !important;
}

.pt-20 {
  padding-top: 20px !important;
}

.pb-20 {
  padding-bottom: 20px !important;
}

.pe-20 {
  padding-right: 20px !important;
}

.ps-20 {
  padding-left: 20px !important;
}

.py-20 {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

.px-20 {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.m-20 {
  margin: 20px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.me-20 {
  margin-right: 20px !important;
}

.ms-20 {
  margin-left: 20px !important;
}

.my-20 {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.mx-20 {
  margin-left: 20px !important;
  margin-right: 20px !important;
}

.p-0 {
  padding: 0px !important;
}

.pt-0 {
  padding-top: 0px !important;
}

.pb-0 {
  padding-bottom: 0px !important;
}

.pe-0 {
  padding-right: 0px !important;
}

.ps-0 {
  padding-left: 0px !important;
}

.py-0 {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.px-0 {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.m-0 {
  margin: 0px !important;
}

.mt-0 {
  margin-top: 0px !important;
}

.mb-0 {
  margin-bottom: 0px !important;
}

.me-0 {
  margin-right: 0px !important;
}

.ms-0 {
  margin-left: 0px !important;
}

.my-0 {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.mx-0 {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.p-15 {
  padding: 15px !important;
}

.pt-15 {
  padding-top: 15px !important;
}

.pb-15 {
  padding-bottom: 15px !important;
}

.pe-15 {
  padding-right: 15px !important;
}

.ps-15 {
  padding-left: 15px !important;
}

.py-15 {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}

.px-15 {
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.m-15 {
  margin: 15px !important;
}

.mt-15 {
  margin-top: 15px !important;
}

.mb-15 {
  margin-bottom: 15px !important;
}

.me-15 {
  margin-right: 15px !important;
}

.ms-15 {
  margin-left: 15px !important;
}

.my-15 {
  margin-top: 15px !important;
  margin-bottom: 15px !important;
}

.mx-15 {
  margin-left: 15px !important;
  margin-right: 15px !important;
}

.p-30 {
  padding: 30px !important;
}

.pt-30 {
  padding-top: 30px !important;
}

.pb-30 {
  padding-bottom: 30px !important;
}

.pe-30 {
  padding-right: 30px !important;
}

.ps-30 {
  padding-left: 30px !important;
}

.py-30 {
  padding-top: 30px !important;
  padding-bottom: 30px !important;
}

.px-30 {
  padding-left: 30px !important;
  padding-right: 30px !important;
}

.m-30 {
  margin: 30px !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.me-30 {
  margin-right: 30px !important;
}

.ms-30 {
  margin-left: 30px !important;
}

.my-30 {
  margin-top: 30px !important;
  margin-bottom: 30px !important;
}

.mx-30 {
  margin-left: 30px !important;
  margin-right: 30px !important;
}

.p-45 {
  padding: 45px !important;
}

.pt-45 {
  padding-top: 45px !important;
}

.pb-45 {
  padding-bottom: 45px !important;
}

.pe-45 {
  padding-right: 45px !important;
}

.ps-45 {
  padding-left: 45px !important;
}

.py-45 {
  padding-top: 45px !important;
  padding-bottom: 45px !important;
}

.px-45 {
  padding-left: 45px !important;
  padding-right: 45px !important;
}

.m-45 {
  margin: 45px !important;
}

.mt-45 {
  margin-top: 45px !important;
}

.mb-45 {
  margin-bottom: 45px !important;
}

.me-45 {
  margin-right: 45px !important;
}

.ms-45 {
  margin-left: 45px !important;
}

.my-45 {
  margin-top: 45px !important;
  margin-bottom: 45px !important;
}

.mx-45 {
  margin-left: 45px !important;
  margin-right: 45px !important;
}

.p-60 {
  padding: 60px !important;
}

.pt-60 {
  padding-top: 60px !important;
}

.pb-60 {
  padding-bottom: 60px !important;
}

.pe-60 {
  padding-right: 60px !important;
}

.ps-60 {
  padding-left: 60px !important;
}

.py-60 {
  padding-top: 60px !important;
  padding-bottom: 60px !important;
}

.px-60 {
  padding-left: 60px !important;
  padding-right: 60px !important;
}

.m-60 {
  margin: 60px !important;
}

.mt-60 {
  margin-top: 60px !important;
}

.mb-60 {
  margin-bottom: 60px !important;
}

.me-60 {
  margin-right: 60px !important;
}

.ms-60 {
  margin-left: 60px !important;
}

.my-60 {
  margin-top: 60px !important;
  margin-bottom: 60px !important;
}

.mx-60 {
  margin-left: 60px !important;
  margin-right: 60px !important;
}

@media (min-width: 720px) {
  .p-md-20 {
    padding: 20px !important;
  }
  .pt-md-20 {
    padding-top: 20px !important;
  }
  .pb-md-20 {
    padding-bottom: 20px !important;
  }
  .pe-md-20 {
    padding-right: 20px !important;
  }
  .ps-md-20 {
    padding-left: 20px !important;
  }
  .py-md-20 {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
  .px-md-20 {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  .m-md-20 {
    margin: 20px !important;
  }
  .mt-md-20 {
    margin-top: 20px !important;
  }
  .mb-md-20 {
    margin-bottom: 20px !important;
  }
  .me-md-20 {
    margin-right: 20px !important;
  }
  .ms-md-20 {
    margin-left: 20px !important;
  }
  .my-md-20 {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }
  .mx-md-20 {
    margin-left: 20px !important;
    margin-right: 20px !important;
  }
}

@media (min-width: 720px) {
  .p-md-0 {
    padding: 0px !important;
  }
  .pt-md-0 {
    padding-top: 0px !important;
  }
  .pb-md-0 {
    padding-bottom: 0px !important;
  }
  .pe-md-0 {
    padding-right: 0px !important;
  }
  .ps-md-0 {
    padding-left: 0px !important;
  }
  .py-md-0 {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
  .px-md-0 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .m-md-0 {
    margin: 0px !important;
  }
  .mt-md-0 {
    margin-top: 0px !important;
  }
  .mb-md-0 {
    margin-bottom: 0px !important;
  }
  .me-md-0 {
    margin-right: 0px !important;
  }
  .ms-md-0 {
    margin-left: 0px !important;
  }
  .my-md-0 {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }
  .mx-md-0 {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
}

@media (min-width: 720px) {
  .p-md-15 {
    padding: 15px !important;
  }
  .pt-md-15 {
    padding-top: 15px !important;
  }
  .pb-md-15 {
    padding-bottom: 15px !important;
  }
  .pe-md-15 {
    padding-right: 15px !important;
  }
  .ps-md-15 {
    padding-left: 15px !important;
  }
  .py-md-15 {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }
  .px-md-15 {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .m-md-15 {
    margin: 15px !important;
  }
  .mt-md-15 {
    margin-top: 15px !important;
  }
  .mb-md-15 {
    margin-bottom: 15px !important;
  }
  .me-md-15 {
    margin-right: 15px !important;
  }
  .ms-md-15 {
    margin-left: 15px !important;
  }
  .my-md-15 {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }
  .mx-md-15 {
    margin-left: 15px !important;
    margin-right: 15px !important;
  }
}

@media (min-width: 720px) {
  .p-md-30 {
    padding: 30px !important;
  }
  .pt-md-30 {
    padding-top: 30px !important;
  }
  .pb-md-30 {
    padding-bottom: 30px !important;
  }
  .pe-md-30 {
    padding-right: 30px !important;
  }
  .ps-md-30 {
    padding-left: 30px !important;
  }
  .py-md-30 {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }
  .px-md-30 {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
  .m-md-30 {
    margin: 30px !important;
  }
  .mt-md-30 {
    margin-top: 30px !important;
  }
  .mb-md-30 {
    margin-bottom: 30px !important;
  }
  .me-md-30 {
    margin-right: 30px !important;
  }
  .ms-md-30 {
    margin-left: 30px !important;
  }
  .my-md-30 {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }
  .mx-md-30 {
    margin-left: 30px !important;
    margin-right: 30px !important;
  }
}

@media (min-width: 720px) {
  .p-md-45 {
    padding: 45px !important;
  }
  .pt-md-45 {
    padding-top: 45px !important;
  }
  .pb-md-45 {
    padding-bottom: 45px !important;
  }
  .pe-md-45 {
    padding-right: 45px !important;
  }
  .ps-md-45 {
    padding-left: 45px !important;
  }
  .py-md-45 {
    padding-top: 45px !important;
    padding-bottom: 45px !important;
  }
  .px-md-45 {
    padding-left: 45px !important;
    padding-right: 45px !important;
  }
  .m-md-45 {
    margin: 45px !important;
  }
  .mt-md-45 {
    margin-top: 45px !important;
  }
  .mb-md-45 {
    margin-bottom: 45px !important;
  }
  .me-md-45 {
    margin-right: 45px !important;
  }
  .ms-md-45 {
    margin-left: 45px !important;
  }
  .my-md-45 {
    margin-top: 45px !important;
    margin-bottom: 45px !important;
  }
  .mx-md-45 {
    margin-left: 45px !important;
    margin-right: 45px !important;
  }
}

@media (min-width: 720px) {
  .p-md-60 {
    padding: 60px !important;
  }
  .pt-md-60 {
    padding-top: 60px !important;
  }
  .pb-md-60 {
    padding-bottom: 60px !important;
  }
  .pe-md-60 {
    padding-right: 60px !important;
  }
  .ps-md-60 {
    padding-left: 60px !important;
  }
  .py-md-60 {
    padding-top: 60px !important;
    padding-bottom: 60px !important;
  }
  .px-md-60 {
    padding-left: 60px !important;
    padding-right: 60px !important;
  }
  .m-md-60 {
    margin: 60px !important;
  }
  .mt-md-60 {
    margin-top: 60px !important;
  }
  .mb-md-60 {
    margin-bottom: 60px !important;
  }
  .me-md-60 {
    margin-right: 60px !important;
  }
  .ms-md-60 {
    margin-left: 60px !important;
  }
  .my-md-60 {
    margin-top: 60px !important;
    margin-bottom: 60px !important;
  }
  .mx-md-60 {
    margin-left: 60px !important;
    margin-right: 60px !important;
  }
}

@media (min-width: 1200px) {
  .p-lg-20 {
    padding: 20px !important;
  }
  .pt-lg-20 {
    padding-top: 20px !important;
  }
  .pb-lg-20 {
    padding-bottom: 20px !important;
  }
  .pe-lg-20 {
    padding-right: 20px !important;
  }
  .ps-lg-20 {
    padding-left: 20px !important;
  }
  .py-lg-20 {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
  .px-lg-20 {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  .m-lg-20 {
    margin: 20px !important;
  }
  .mt-lg-20 {
    margin-top: 20px !important;
  }
  .mb-lg-20 {
    margin-bottom: 20px !important;
  }
  .me-lg-20 {
    margin-right: 20px !important;
  }
  .ms-lg-20 {
    margin-left: 20px !important;
  }
  .my-lg-20 {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }
  .mx-lg-20 {
    margin-left: 20px !important;
    margin-right: 20px !important;
  }
}

@media (min-width: 1200px) {
  .p-lg-0 {
    padding: 0px !important;
  }
  .pt-lg-0 {
    padding-top: 0px !important;
  }
  .pb-lg-0 {
    padding-bottom: 0px !important;
  }
  .pe-lg-0 {
    padding-right: 0px !important;
  }
  .ps-lg-0 {
    padding-left: 0px !important;
  }
  .py-lg-0 {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
  .px-lg-0 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .m-lg-0 {
    margin: 0px !important;
  }
  .mt-lg-0 {
    margin-top: 0px !important;
  }
  .mb-lg-0 {
    margin-bottom: 0px !important;
  }
  .me-lg-0 {
    margin-right: 0px !important;
  }
  .ms-lg-0 {
    margin-left: 0px !important;
  }
  .my-lg-0 {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }
  .mx-lg-0 {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
}

@media (min-width: 1200px) {
  .p-lg-15 {
    padding: 15px !important;
  }
  .pt-lg-15 {
    padding-top: 15px !important;
  }
  .pb-lg-15 {
    padding-bottom: 15px !important;
  }
  .pe-lg-15 {
    padding-right: 15px !important;
  }
  .ps-lg-15 {
    padding-left: 15px !important;
  }
  .py-lg-15 {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }
  .px-lg-15 {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .m-lg-15 {
    margin: 15px !important;
  }
  .mt-lg-15 {
    margin-top: 15px !important;
  }
  .mb-lg-15 {
    margin-bottom: 15px !important;
  }
  .me-lg-15 {
    margin-right: 15px !important;
  }
  .ms-lg-15 {
    margin-left: 15px !important;
  }
  .my-lg-15 {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }
  .mx-lg-15 {
    margin-left: 15px !important;
    margin-right: 15px !important;
  }
}

@media (min-width: 1200px) {
  .p-lg-30 {
    padding: 30px !important;
  }
  .pt-lg-30 {
    padding-top: 30px !important;
  }
  .pb-lg-30 {
    padding-bottom: 30px !important;
  }
  .pe-lg-30 {
    padding-right: 30px !important;
  }
  .ps-lg-30 {
    padding-left: 30px !important;
  }
  .py-lg-30 {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }
  .px-lg-30 {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
  .m-lg-30 {
    margin: 30px !important;
  }
  .mt-lg-30 {
    margin-top: 30px !important;
  }
  .mb-lg-30 {
    margin-bottom: 30px !important;
  }
  .me-lg-30 {
    margin-right: 30px !important;
  }
  .ms-lg-30 {
    margin-left: 30px !important;
  }
  .my-lg-30 {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }
  .mx-lg-30 {
    margin-left: 30px !important;
    margin-right: 30px !important;
  }
}

@media (min-width: 1200px) {
  .p-lg-45 {
    padding: 45px !important;
  }
  .pt-lg-45 {
    padding-top: 45px !important;
  }
  .pb-lg-45 {
    padding-bottom: 45px !important;
  }
  .pe-lg-45 {
    padding-right: 45px !important;
  }
  .ps-lg-45 {
    padding-left: 45px !important;
  }
  .py-lg-45 {
    padding-top: 45px !important;
    padding-bottom: 45px !important;
  }
  .px-lg-45 {
    padding-left: 45px !important;
    padding-right: 45px !important;
  }
  .m-lg-45 {
    margin: 45px !important;
  }
  .mt-lg-45 {
    margin-top: 45px !important;
  }
  .mb-lg-45 {
    margin-bottom: 45px !important;
  }
  .me-lg-45 {
    margin-right: 45px !important;
  }
  .ms-lg-45 {
    margin-left: 45px !important;
  }
  .my-lg-45 {
    margin-top: 45px !important;
    margin-bottom: 45px !important;
  }
  .mx-lg-45 {
    margin-left: 45px !important;
    margin-right: 45px !important;
  }
}

@media (min-width: 1200px) {
  .p-lg-60 {
    padding: 60px !important;
  }
  .pt-lg-60 {
    padding-top: 60px !important;
  }
  .pb-lg-60 {
    padding-bottom: 60px !important;
  }
  .pe-lg-60 {
    padding-right: 60px !important;
  }
  .ps-lg-60 {
    padding-left: 60px !important;
  }
  .py-lg-60 {
    padding-top: 60px !important;
    padding-bottom: 60px !important;
  }
  .px-lg-60 {
    padding-left: 60px !important;
    padding-right: 60px !important;
  }
  .m-lg-60 {
    margin: 60px !important;
  }
  .mt-lg-60 {
    margin-top: 60px !important;
  }
  .mb-lg-60 {
    margin-bottom: 60px !important;
  }
  .me-lg-60 {
    margin-right: 60px !important;
  }
  .ms-lg-60 {
    margin-left: 60px !important;
  }
  .my-lg-60 {
    margin-top: 60px !important;
    margin-bottom: 60px !important;
  }
  .mx-lg-60 {
    margin-left: 60px !important;
    margin-right: 60px !important;
  }
}

.p--20 {
  padding: -20px !important;
}

.pt--20 {
  padding-top: -20px !important;
}

.pb--20 {
  padding-bottom: -20px !important;
}

.pe--20 {
  padding-right: -20px !important;
}

.ps--20 {
  padding-left: -20px !important;
}

.py--20 {
  padding-top: -20px !important;
  padding-bottom: -20px !important;
}

.px--20 {
  padding-left: -20px !important;
  padding-right: -20px !important;
}

.m--20 {
  margin: -20px !important;
}

.mt--20 {
  margin-top: -20px !important;
}

.mb--20 {
  margin-bottom: -20px !important;
}

.me--20 {
  margin-right: -20px !important;
}

.ms--20 {
  margin-left: -20px !important;
}

.my--20 {
  margin-top: -20px !important;
  margin-bottom: -20px !important;
}

.mx--20 {
  margin-left: -20px !important;
  margin-right: -20px !important;
}

.p--0 {
  padding: 0px !important;
}

.pt--0 {
  padding-top: 0px !important;
}

.pb--0 {
  padding-bottom: 0px !important;
}

.pe--0 {
  padding-right: 0px !important;
}

.ps--0 {
  padding-left: 0px !important;
}

.py--0 {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.px--0 {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.m--0 {
  margin: 0px !important;
}

.mt--0 {
  margin-top: 0px !important;
}

.mb--0 {
  margin-bottom: 0px !important;
}

.me--0 {
  margin-right: 0px !important;
}

.ms--0 {
  margin-left: 0px !important;
}

.my--0 {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.mx--0 {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.p--15 {
  padding: -15px !important;
}

.pt--15 {
  padding-top: -15px !important;
}

.pb--15 {
  padding-bottom: -15px !important;
}

.pe--15 {
  padding-right: -15px !important;
}

.ps--15 {
  padding-left: -15px !important;
}

.py--15 {
  padding-top: -15px !important;
  padding-bottom: -15px !important;
}

.px--15 {
  padding-left: -15px !important;
  padding-right: -15px !important;
}

.m--15 {
  margin: -15px !important;
}

.mt--15 {
  margin-top: -15px !important;
}

.mb--15 {
  margin-bottom: -15px !important;
}

.me--15 {
  margin-right: -15px !important;
}

.ms--15 {
  margin-left: -15px !important;
}

.my--15 {
  margin-top: -15px !important;
  margin-bottom: -15px !important;
}

.mx--15 {
  margin-left: -15px !important;
  margin-right: -15px !important;
}

.p--30 {
  padding: -30px !important;
}

.pt--30 {
  padding-top: -30px !important;
}

.pb--30 {
  padding-bottom: -30px !important;
}

.pe--30 {
  padding-right: -30px !important;
}

.ps--30 {
  padding-left: -30px !important;
}

.py--30 {
  padding-top: -30px !important;
  padding-bottom: -30px !important;
}

.px--30 {
  padding-left: -30px !important;
  padding-right: -30px !important;
}

.m--30 {
  margin: -30px !important;
}

.mt--30 {
  margin-top: -30px !important;
}

.mb--30 {
  margin-bottom: -30px !important;
}

.me--30 {
  margin-right: -30px !important;
}

.ms--30 {
  margin-left: -30px !important;
}

.my--30 {
  margin-top: -30px !important;
  margin-bottom: -30px !important;
}

.mx--30 {
  margin-left: -30px !important;
  margin-right: -30px !important;
}

.p--45 {
  padding: -45px !important;
}

.pt--45 {
  padding-top: -45px !important;
}

.pb--45 {
  padding-bottom: -45px !important;
}

.pe--45 {
  padding-right: -45px !important;
}

.ps--45 {
  padding-left: -45px !important;
}

.py--45 {
  padding-top: -45px !important;
  padding-bottom: -45px !important;
}

.px--45 {
  padding-left: -45px !important;
  padding-right: -45px !important;
}

.m--45 {
  margin: -45px !important;
}

.mt--45 {
  margin-top: -45px !important;
}

.mb--45 {
  margin-bottom: -45px !important;
}

.me--45 {
  margin-right: -45px !important;
}

.ms--45 {
  margin-left: -45px !important;
}

.my--45 {
  margin-top: -45px !important;
  margin-bottom: -45px !important;
}

.mx--45 {
  margin-left: -45px !important;
  margin-right: -45px !important;
}

.p--60 {
  padding: -60px !important;
}

.pt--60 {
  padding-top: -60px !important;
}

.pb--60 {
  padding-bottom: -60px !important;
}

.pe--60 {
  padding-right: -60px !important;
}

.ps--60 {
  padding-left: -60px !important;
}

.py--60 {
  padding-top: -60px !important;
  padding-bottom: -60px !important;
}

.px--60 {
  padding-left: -60px !important;
  padding-right: -60px !important;
}

.m--60 {
  margin: -60px !important;
}

.mt--60 {
  margin-top: -60px !important;
}

.mb--60 {
  margin-bottom: -60px !important;
}

.me--60 {
  margin-right: -60px !important;
}

.ms--60 {
  margin-left: -60px !important;
}

.my--60 {
  margin-top: -60px !important;
  margin-bottom: -60px !important;
}

.mx--60 {
  margin-left: -60px !important;
  margin-right: -60px !important;
}

@media (min-width: 720px) {
  .p-md--20 {
    padding: -20px !important;
  }
  .pt-md--20 {
    padding-top: -20px !important;
  }
  .pb-md--20 {
    padding-bottom: -20px !important;
  }
  .pe-md--20 {
    padding-right: -20px !important;
  }
  .ps-md--20 {
    padding-left: -20px !important;
  }
  .py-md--20 {
    padding-top: -20px !important;
    padding-bottom: -20px !important;
  }
  .px-md--20 {
    padding-left: -20px !important;
    padding-right: -20px !important;
  }
  .m-md--20 {
    margin: -20px !important;
  }
  .mt-md--20 {
    margin-top: -20px !important;
  }
  .mb-md--20 {
    margin-bottom: -20px !important;
  }
  .me-md--20 {
    margin-right: -20px !important;
  }
  .ms-md--20 {
    margin-left: -20px !important;
  }
  .my-md--20 {
    margin-top: -20px !important;
    margin-bottom: -20px !important;
  }
  .mx-md--20 {
    margin-left: -20px !important;
    margin-right: -20px !important;
  }
}

@media (min-width: 720px) {
  .p-md--0 {
    padding: 0px !important;
  }
  .pt-md--0 {
    padding-top: 0px !important;
  }
  .pb-md--0 {
    padding-bottom: 0px !important;
  }
  .pe-md--0 {
    padding-right: 0px !important;
  }
  .ps-md--0 {
    padding-left: 0px !important;
  }
  .py-md--0 {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
  .px-md--0 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .m-md--0 {
    margin: 0px !important;
  }
  .mt-md--0 {
    margin-top: 0px !important;
  }
  .mb-md--0 {
    margin-bottom: 0px !important;
  }
  .me-md--0 {
    margin-right: 0px !important;
  }
  .ms-md--0 {
    margin-left: 0px !important;
  }
  .my-md--0 {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }
  .mx-md--0 {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
}

@media (min-width: 720px) {
  .p-md--15 {
    padding: -15px !important;
  }
  .pt-md--15 {
    padding-top: -15px !important;
  }
  .pb-md--15 {
    padding-bottom: -15px !important;
  }
  .pe-md--15 {
    padding-right: -15px !important;
  }
  .ps-md--15 {
    padding-left: -15px !important;
  }
  .py-md--15 {
    padding-top: -15px !important;
    padding-bottom: -15px !important;
  }
  .px-md--15 {
    padding-left: -15px !important;
    padding-right: -15px !important;
  }
  .m-md--15 {
    margin: -15px !important;
  }
  .mt-md--15 {
    margin-top: -15px !important;
  }
  .mb-md--15 {
    margin-bottom: -15px !important;
  }
  .me-md--15 {
    margin-right: -15px !important;
  }
  .ms-md--15 {
    margin-left: -15px !important;
  }
  .my-md--15 {
    margin-top: -15px !important;
    margin-bottom: -15px !important;
  }
  .mx-md--15 {
    margin-left: -15px !important;
    margin-right: -15px !important;
  }
}

@media (min-width: 720px) {
  .p-md--30 {
    padding: -30px !important;
  }
  .pt-md--30 {
    padding-top: -30px !important;
  }
  .pb-md--30 {
    padding-bottom: -30px !important;
  }
  .pe-md--30 {
    padding-right: -30px !important;
  }
  .ps-md--30 {
    padding-left: -30px !important;
  }
  .py-md--30 {
    padding-top: -30px !important;
    padding-bottom: -30px !important;
  }
  .px-md--30 {
    padding-left: -30px !important;
    padding-right: -30px !important;
  }
  .m-md--30 {
    margin: -30px !important;
  }
  .mt-md--30 {
    margin-top: -30px !important;
  }
  .mb-md--30 {
    margin-bottom: -30px !important;
  }
  .me-md--30 {
    margin-right: -30px !important;
  }
  .ms-md--30 {
    margin-left: -30px !important;
  }
  .my-md--30 {
    margin-top: -30px !important;
    margin-bottom: -30px !important;
  }
  .mx-md--30 {
    margin-left: -30px !important;
    margin-right: -30px !important;
  }
}

@media (min-width: 720px) {
  .p-md--45 {
    padding: -45px !important;
  }
  .pt-md--45 {
    padding-top: -45px !important;
  }
  .pb-md--45 {
    padding-bottom: -45px !important;
  }
  .pe-md--45 {
    padding-right: -45px !important;
  }
  .ps-md--45 {
    padding-left: -45px !important;
  }
  .py-md--45 {
    padding-top: -45px !important;
    padding-bottom: -45px !important;
  }
  .px-md--45 {
    padding-left: -45px !important;
    padding-right: -45px !important;
  }
  .m-md--45 {
    margin: -45px !important;
  }
  .mt-md--45 {
    margin-top: -45px !important;
  }
  .mb-md--45 {
    margin-bottom: -45px !important;
  }
  .me-md--45 {
    margin-right: -45px !important;
  }
  .ms-md--45 {
    margin-left: -45px !important;
  }
  .my-md--45 {
    margin-top: -45px !important;
    margin-bottom: -45px !important;
  }
  .mx-md--45 {
    margin-left: -45px !important;
    margin-right: -45px !important;
  }
}

@media (min-width: 720px) {
  .p-md--60 {
    padding: -60px !important;
  }
  .pt-md--60 {
    padding-top: -60px !important;
  }
  .pb-md--60 {
    padding-bottom: -60px !important;
  }
  .pe-md--60 {
    padding-right: -60px !important;
  }
  .ps-md--60 {
    padding-left: -60px !important;
  }
  .py-md--60 {
    padding-top: -60px !important;
    padding-bottom: -60px !important;
  }
  .px-md--60 {
    padding-left: -60px !important;
    padding-right: -60px !important;
  }
  .m-md--60 {
    margin: -60px !important;
  }
  .mt-md--60 {
    margin-top: -60px !important;
  }
  .mb-md--60 {
    margin-bottom: -60px !important;
  }
  .me-md--60 {
    margin-right: -60px !important;
  }
  .ms-md--60 {
    margin-left: -60px !important;
  }
  .my-md--60 {
    margin-top: -60px !important;
    margin-bottom: -60px !important;
  }
  .mx-md--60 {
    margin-left: -60px !important;
    margin-right: -60px !important;
  }
}

@media (min-width: 1200px) {
  .p-lg--20 {
    padding: -20px !important;
  }
  .pt-lg--20 {
    padding-top: -20px !important;
  }
  .pb-lg--20 {
    padding-bottom: -20px !important;
  }
  .pe-lg--20 {
    padding-right: -20px !important;
  }
  .ps-lg--20 {
    padding-left: -20px !important;
  }
  .py-lg--20 {
    padding-top: -20px !important;
    padding-bottom: -20px !important;
  }
  .px-lg--20 {
    padding-left: -20px !important;
    padding-right: -20px !important;
  }
  .m-lg--20 {
    margin: -20px !important;
  }
  .mt-lg--20 {
    margin-top: -20px !important;
  }
  .mb-lg--20 {
    margin-bottom: -20px !important;
  }
  .me-lg--20 {
    margin-right: -20px !important;
  }
  .ms-lg--20 {
    margin-left: -20px !important;
  }
  .my-lg--20 {
    margin-top: -20px !important;
    margin-bottom: -20px !important;
  }
  .mx-lg--20 {
    margin-left: -20px !important;
    margin-right: -20px !important;
  }
}

@media (min-width: 1200px) {
  .p-lg--0 {
    padding: 0px !important;
  }
  .pt-lg--0 {
    padding-top: 0px !important;
  }
  .pb-lg--0 {
    padding-bottom: 0px !important;
  }
  .pe-lg--0 {
    padding-right: 0px !important;
  }
  .ps-lg--0 {
    padding-left: 0px !important;
  }
  .py-lg--0 {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
  .px-lg--0 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .m-lg--0 {
    margin: 0px !important;
  }
  .mt-lg--0 {
    margin-top: 0px !important;
  }
  .mb-lg--0 {
    margin-bottom: 0px !important;
  }
  .me-lg--0 {
    margin-right: 0px !important;
  }
  .ms-lg--0 {
    margin-left: 0px !important;
  }
  .my-lg--0 {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }
  .mx-lg--0 {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
}

@media (min-width: 1200px) {
  .p-lg--15 {
    padding: -15px !important;
  }
  .pt-lg--15 {
    padding-top: -15px !important;
  }
  .pb-lg--15 {
    padding-bottom: -15px !important;
  }
  .pe-lg--15 {
    padding-right: -15px !important;
  }
  .ps-lg--15 {
    padding-left: -15px !important;
  }
  .py-lg--15 {
    padding-top: -15px !important;
    padding-bottom: -15px !important;
  }
  .px-lg--15 {
    padding-left: -15px !important;
    padding-right: -15px !important;
  }
  .m-lg--15 {
    margin: -15px !important;
  }
  .mt-lg--15 {
    margin-top: -15px !important;
  }
  .mb-lg--15 {
    margin-bottom: -15px !important;
  }
  .me-lg--15 {
    margin-right: -15px !important;
  }
  .ms-lg--15 {
    margin-left: -15px !important;
  }
  .my-lg--15 {
    margin-top: -15px !important;
    margin-bottom: -15px !important;
  }
  .mx-lg--15 {
    margin-left: -15px !important;
    margin-right: -15px !important;
  }
}

@media (min-width: 1200px) {
  .p-lg--30 {
    padding: -30px !important;
  }
  .pt-lg--30 {
    padding-top: -30px !important;
  }
  .pb-lg--30 {
    padding-bottom: -30px !important;
  }
  .pe-lg--30 {
    padding-right: -30px !important;
  }
  .ps-lg--30 {
    padding-left: -30px !important;
  }
  .py-lg--30 {
    padding-top: -30px !important;
    padding-bottom: -30px !important;
  }
  .px-lg--30 {
    padding-left: -30px !important;
    padding-right: -30px !important;
  }
  .m-lg--30 {
    margin: -30px !important;
  }
  .mt-lg--30 {
    margin-top: -30px !important;
  }
  .mb-lg--30 {
    margin-bottom: -30px !important;
  }
  .me-lg--30 {
    margin-right: -30px !important;
  }
  .ms-lg--30 {
    margin-left: -30px !important;
  }
  .my-lg--30 {
    margin-top: -30px !important;
    margin-bottom: -30px !important;
  }
  .mx-lg--30 {
    margin-left: -30px !important;
    margin-right: -30px !important;
  }
}

@media (min-width: 1200px) {
  .p-lg--45 {
    padding: -45px !important;
  }
  .pt-lg--45 {
    padding-top: -45px !important;
  }
  .pb-lg--45 {
    padding-bottom: -45px !important;
  }
  .pe-lg--45 {
    padding-right: -45px !important;
  }
  .ps-lg--45 {
    padding-left: -45px !important;
  }
  .py-lg--45 {
    padding-top: -45px !important;
    padding-bottom: -45px !important;
  }
  .px-lg--45 {
    padding-left: -45px !important;
    padding-right: -45px !important;
  }
  .m-lg--45 {
    margin: -45px !important;
  }
  .mt-lg--45 {
    margin-top: -45px !important;
  }
  .mb-lg--45 {
    margin-bottom: -45px !important;
  }
  .me-lg--45 {
    margin-right: -45px !important;
  }
  .ms-lg--45 {
    margin-left: -45px !important;
  }
  .my-lg--45 {
    margin-top: -45px !important;
    margin-bottom: -45px !important;
  }
  .mx-lg--45 {
    margin-left: -45px !important;
    margin-right: -45px !important;
  }
}

@media (min-width: 1200px) {
  .p-lg--60 {
    padding: -60px !important;
  }
  .pt-lg--60 {
    padding-top: -60px !important;
  }
  .pb-lg--60 {
    padding-bottom: -60px !important;
  }
  .pe-lg--60 {
    padding-right: -60px !important;
  }
  .ps-lg--60 {
    padding-left: -60px !important;
  }
  .py-lg--60 {
    padding-top: -60px !important;
    padding-bottom: -60px !important;
  }
  .px-lg--60 {
    padding-left: -60px !important;
    padding-right: -60px !important;
  }
  .m-lg--60 {
    margin: -60px !important;
  }
  .mt-lg--60 {
    margin-top: -60px !important;
  }
  .mb-lg--60 {
    margin-bottom: -60px !important;
  }
  .me-lg--60 {
    margin-right: -60px !important;
  }
  .ms-lg--60 {
    margin-left: -60px !important;
  }
  .my-lg--60 {
    margin-top: -60px !important;
    margin-bottom: -60px !important;
  }
  .mx-lg--60 {
    margin-left: -60px !important;
    margin-right: -60px !important;
  }
}

html,
body {
  margin: 0;
  padding: 0;
  background-color: #fbfeff;
  font-family: Arial;
  font-weight: 400;
  font-size: 14px;
  line-height: normal;
  letter-spacing: normal;
  color: #11192b;
}

* {
  box-sizing: border-box;
}

img,
svg {
  max-width: 100%;
  max-height: 100%;
}

.overflow-hidden {
  overflow: hidden;
}

.mainwrap {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.mainwrap > * {
  flex: 0 0 auto;
}

.mainwrap main {
  flex-grow: 1;
  padding-top: 133px;
}

@media (max-width: 719px) {
  .mainwrap main {
    padding-top: 48px;
  }
}

.container {
  width: 1140px;
  max-width: calc(100% - 90px);
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 1199px) {
  .container {
    max-width: calc(100% - 28px);
  }
}

@media (max-width: 719px) {
  .container {
    max-width: calc(100% - 24px);
  }
}

.popup {
  position: fixed;
  z-index: 999;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: 0.4s;
  pointer-events: none;
}

.popup.js--open {
  opacity: 1;
  pointer-events: auto;
}

.popup__overlay {
  background-color: #11192b;
  opacity: 0.6;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.popup__inner {
  z-index: 1;
  overflow: hidden;
  width: 560px;
  height: 700px;
  max-height: 100%;
  max-width: 100%;
  border-radius: 4px;
  background-color: #ffffff;
}

.popup__bg-1 {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  line-height: 0;
}

.popup__bg-1::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 324px;
  height: 324px;
  background-color: #fff;
  border-radius: 50%;
  transform: translateX(-50%);
}

.popup__bg-2 {
  position: absolute;
  z-index: 2;
  left: 0;
  top: 8px;
  line-height: 0;
}

.popup__close {
  width: 18px;
  height: 18px;
  fill: #11192b;
  right: 38px;
  top: 38px;
  position: absolute;
}

@media (hover: hover) and (pointer: fine) {
  .popup__close {
    transition: 0.2s;
  }
  .popup__close:hover {
    transform: scale(1.15) rotate(90deg);
  }
}

.popup__scroll {
  overflow: auto;
  height: 100%;
  padding-top: 180px;
  padding-left: 190px;
  padding-bottom: 30px;
  padding-right: 30px;
  position: relative;
}

.popup__title {
  color: #11192b;
  font-size: 32px;
  font-weight: 700;
  line-height: 36px;
}

.popup--open {
  overflow: hidden;
}

@media (max-width: 719px) {
  .popup__inner {
    max-width: 360px;
    height: auto;
    position: relative;
  }
}

@media (max-width: 719px) and (max-height: 600px) {
  .popup__inner {
    min-height: 100%;
  }
}

@media (max-width: 719px) {
  .popup__close {
    right: 10px;
    top: 10px;
  }
  .popup__scroll {
    padding: 45px 15px 15px;
  }
  .popup__bg-1, .popup__bg-2 {
    display: none;
  }
}

@media (max-width: 360px) {
  .popup__inner {
    border-radius: 0;
  }
}

.title-1 {
  color: #11192b;
  font-size: 32px;
  font-weight: 700;
  line-height: 36px;
}

@media (max-width: 719px) {
  .title-1 {
    font-size: 21px;
    line-height: 24px;
  }
}

.title-2 {
  color: #11192b;
  font-size: 24px;
  font-weight: 700;
}

@media (max-width: 719px) {
  .title-2 {
    font-size: 16px;
  }
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.link, .mainwrap .news__link {
  color: #11192b;
  text-decoration: none;
}

@media (hover: hover) and (pointer: fine) {
  .link, .mainwrap .news__link {
    transition: 0.2s;
  }
  .link:hover, .mainwrap .news__link:hover {
    cursor: pointer;
  }
}

@media (hover: hover) and (pointer: fine) {
  .link, .mainwrap .news__link {
    transition: 0.2s;
  }
  .link:hover, .mainwrap .news__link:hover {
    color: #079e65;
  }
}

.text-gray {
  color: #a0a3aa;
}

.text-green {
  color: #079e65;
}

.text-blue {
  color: #4a6db2;
}

.text-blue-light {
  color: #46b8e7;
}

.doc-link {
  display: inline-flex;
  max-width: 100%;
  width: 720px;
  color: #11192b;
  font-size: 14px;
  text-decoration: none;
}

@media (hover: hover) and (pointer: fine) {
  .doc-link {
    transition: 0.2s;
  }
  .doc-link:hover {
    cursor: pointer;
  }
}

.doc-link:after {
  content: "";
  flex-grow: 1;
}

.doc-link__description {
  display: flex;
  min-height: 24px;
  align-items: flex-start;
}

.doc-link__description svg {
  flex: 0 0 auto;
  margin-top: -4px;
  margin-right: 10px;
}

.doc-link__size {
  flex: 0 0 auto;
  margin-left: 15px;
  order: 1;
  color: #079e65;
  opacity: 0;
  transition: 0.2s;
  display: flex;
  align-items: center;
  margin-top: -8px;
}

@media (max-width: 719px) {
  .doc-link__size {
    display: none;
  }
}

.doc-link__size svg {
  margin-right: 10px;
  fill: #079e65;
  width: 24px;
  height: 24px;
}

@media (hover: hover) and (pointer: fine) {
  .doc-link {
    transition: 0.2s;
  }
  .doc-link:hover {
    color: #079e65;
  }
  .doc-link:hover .doc-link__size {
    opacity: 1;
  }
}

.icon-doc {
  width: 24px;
  height: 24px;
  fill: #079e65;
}

.bg-gray {
  background-color: #dfe1e2;
}

.fz-11 {
  font-size: 11px;
}

.fz-14 {
  font-size: 14px;
}

.btn {
  text-decoration: none !important;
  cursor: pointer;
  max-width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  outline: none;
  border: none;
  background: none;
  transition: 0.2s;
}

.btn-fill-blue {
  padding: 10px 30px;
  height: 36px;
  border-radius: 4px;
  font-size: 14px;
  line-height: 16px;
  font-weight: 700;
  background-image: linear-gradient(to right, #46b8e7 0%, #4a6db2 100%);
  color: #fff;
}

@media (hover: hover) and (pointer: fine) {
  .btn-fill-blue {
    transition: 0.2s;
  }
  .btn-fill-blue:hover {
    background-color: #46b8e7;
  }
}

.btn-fill-blue:active {
  transition: 0.05s;
  background-color: #4a6db2;
}

.btn-border-blue {
  padding: 10px 10px;
  height: 36px;
  border-radius: 4px;
  font-size: 14px;
  line-height: 16px;
  border: 1px solid #458ECA;
  color: #458ECA;
}

@media (hover: hover) and (pointer: fine) {
  .btn-border-blue {
    transition: 0.2s;
  }
  .btn-border-blue:hover {
    border-color: #47A6DA;
    color: #47A6DA;
  }
}

.btn-border-blue:active {
  border-color: #4A70B4;
  color: #4A70B4;
}

.btn-link {
  font-size: 16px;
  line-height: 18px;
  color: #458eca;
}

@media (hover: hover) and (pointer: fine) {
  .btn-link {
    transition: 0.2s;
  }
  .btn-link:hover {
    color: #47A6DA;
  }
}

.btn-link:active {
  color: #4A70B4;
}

.btns {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: -15px;
}

.btns > * {
  margin: 15px;
}

@media (max-width: 719px) {
  .btns {
    margin: -7px;
  }
  .btns > * {
    margin: 7px;
  }
}

[data-toggle],
[data-tab] {
  cursor: pointer;
}

.toggle {
  display: flex;
}

@media (max-width: 719px) {
  .toggle {
    justify-content: space-between;
  }
}

.toggle__arrow {
  flex: 0 0 auto;
  margin-left: 30px;
  width: 24px;
  height: 24px;
  transition: .4s;
}

.toggle.active .toggle__arrow {
  transform: rotate(180deg);
}

.input-text {
  height: 42px;
  border-radius: 4px;
  border: 1px solid #b2b4b8;
  color: #11192b;
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  padding: 8px;
  width: 100%;
}

@media (hover: hover) and (pointer: fine) {
  .input-text {
    transition: 0.2s;
  }
  .input-text:hover {
    border-color: #458eca;
  }
}

.input-text:focus {
  border-color: #458eca;
}

.label-text {
  color: #b2b4b8;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: normal;
  margin-bottom: 4px;
}

label {
  display: block;
}

.mainwrap .header {
  user-select: none;
  position: fixed;
  z-index: 777;
  left: 0;
  right: 0;
  top: 0;
  background-color: #fff;
  border-bottom: 1px solid #eee;
}

.mainwrap .header__top {
  width: calc(100% + 30px);
  display: flex;
  align-items: center;
  margin: 0 -15px;
}

.mainwrap .header__top:before {
  content: "";
  flex: 1 1 auto !important;
}

.mainwrap .header__top > * {
  flex: 0 0 auto;
  margin: 15px;
}

.mainwrap .header__logo {
  order: -1;
  width: 202px;
  height: 54px;
}

.mainwrap .header__phone {
  color: #11192b;
  font-size: 24px;
  font-weight: 700;
  text-decoration: none;
}

.mainwrap .header__nav ul {
  padding: 0;
  list-style-type: none;
  display: flex;
  align-items: center;
  margin: 0 -15px;
}

.mainwrap .header__nav a {
  display: inline-block;
  margin: 15px;
  color: #11192b;
  font-size: 16px;
  text-decoration: none;
  position: relative;
}

.mainwrap .header__nav a:before {
  position: absolute;
  content: "";
  left: 0;
  right: 0;
  top: 100%;
  height: 2px;
  background-color: #458eca;
  opacity: 0;
  transition: 0.2s;
}

@media (hover: hover) and (pointer: fine) {
  .mainwrap .header__nav a {
    transition: 0.2s;
  }
  .mainwrap .header__nav a:hover {
    color: #11192b;
  }
  .mainwrap .header__nav a:hover::before {
    opacity: 1;
  }
}

.mainwrap .header__nav a.active {
  color: #458eca;
}

.mainwrap .header__nav a.active::before {
  opacity: 1;
}

.mainwrap .header__btn-menu {
  display: none;
}

@media (max-width: 719px) {
  .mainwrap .header {
    height: 48px;
  }
  .mainwrap .header__top > * {
    margin: 8px 10px;
  }
  .mainwrap .header__logo {
    width: 116px;
    height: 31px;
  }
  .mainwrap .header__btn-menu {
    display: block;
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
  .mainwrap .header__phone {
    font-size: 14px;
  }
  .mainwrap .header__user, .mainwrap .header__nav {
    display: none;
  }
}

.mainwrap > *:not(.mobile-menu) {
  transform: translateZ(0);
  transform-origin: 0 50vh;
  transition: filter 0.3s, transform 0.3s, opacity 0.3s;
}

.mobile-menu--open .mainwrap > *:not(.mobile-menu) {
  filter: blur(15px);
  transform: translate(60px, 0);
  opacity: 0.5;
  transition: 0.3s;
}

.mobile-menu--open .mainwrap .mobile-menu {
  pointer-events: auto;
  transform: translateX(0);
}

.mainwrap .mobile-menu {
  transition: 0.3s;
  transform: translateX(-100%);
  pointer-events: none;
  width: 220px;
  background-color: #ffffff;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 780;
  display: flex;
  flex-direction: column;
  user-select: none;
  padding: 16px;
}

.mainwrap .mobile-menu::before {
  content: "";
  position: absolute;
  opacity: 0;
  left: 0;
  width: 100vw;
  top: 0;
  bottom: 0;
  z-index: -1;
}

.mainwrap .mobile-menu__close {
  width: 24px;
  height: 24px;
  align-self: flex-end;
}

.mainwrap .mobile-menu > * {
  flex: 0 0 auto;
}

.mainwrap .mobile-menu:after {
  content: "";
  flex-grow: 1;
}

.mainwrap .mobile-menu__phone {
  color: #11192b;
  font-size: 24px;
  font-weight: 700;
  text-decoration: none;
  order: 11;
}

.mainwrap .mobile-menu__phone-text {
  order: 11;
  color: #a0a3aa;
  font-size: 10px;
}

.mainwrap .mobile-menu__nav {
  margin-bottom: 30px;
}

.mainwrap .mobile-menu__nav ul {
  padding: 0;
  list-style-type: none;
}

.mainwrap .mobile-menu__nav a {
  display: block;
  margin: 20px 0;
  color: #11192b;
  font-size: 14px;
  text-decoration: none;
  position: relative;
}

.mainwrap .mobile-menu__nav a:before {
  position: absolute;
  content: "";
  left: 0;
  right: 0;
  bottom: -6px;
  height: 2px;
  background-color: #458eca;
  opacity: 0;
  transition: 0.2s;
}

@media (hover: hover) and (pointer: fine) {
  .mainwrap .mobile-menu__nav a {
    transition: 0.2s;
  }
  .mainwrap .mobile-menu__nav a:hover {
    color: #11192b;
  }
  .mainwrap .mobile-menu__nav a:hover::before {
    opacity: 1;
  }
}

.mainwrap .mobile-menu__nav a.active {
  color: #458eca;
}

.mainwrap .mobile-menu__nav a.active::before {
  opacity: 1;
}

.mainwrap .footer {
  padding: 30px 0;
  color: #ffffff;
  font-size: 16px;
  background-color: #11192b;
}

@media (max-width: 719px) {
  .mainwrap .footer {
    font-size: 11px;
  }
}

.mainwrap .footer__row {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
}

.mainwrap .footer__col {
  width: calc(50% - 30px);
  margin: 15px;
}

@media (max-width: 719px) {
  .mainwrap .footer__col {
    width: calc(100% - 30px);
    margin: 8px 15px;
  }
}

.mainwrap .footer__col._rights {
  order: 11;
}

.mainwrap .footer__link {
  text-decoration: none;
  color: white;
  font-weight: 700;
}

@media (hover: hover) and (pointer: fine) {
  .mainwrap .footer__link {
    transition: 0.2s;
  }
  .mainwrap .footer__link:hover {
    text-decoration: underline;
  }
}

.mainwrap .news {
  margin: 20px 0;
}

.mainwrap .news__date {
  color: #a0a3aa;
  margin-bottom: 8px;
}

.mainwrap .news__title {
  color: #11192b;
  font-size: 18px;
  font-weight: 700;
  line-height: 22.5px;
}

.mainwrap .cover {
  background-color: #dfe1e2;
}

.mainwrap .cover .container {
  display: flex;
  justify-content: space-between;
}

.mainwrap .cover__info {
  width: 380px;
  display: flex;
  flex-direction: column;
  flex: 0 1 auto;
  align-items: flex-start;
  max-width: 100%;
}

.mainwrap .cover__text {
  padding: 60px 0;
  flex: 1 0 auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.mainwrap .cover__subtitle {
  font-size: 18px;
  margin-top: 24px;
}

.mainwrap .cover__img {
  flex: 0 0 auto;
  margin-left: 30px;
  line-height: 0;
}

.mainwrap .cover__btn {
  padding-bottom: 60px;
}

@media (max-width: 719px) {
  .mainwrap .cover {
    text-align: center;
  }
  .mainwrap .cover .container {
    flex-direction: column;
    align-items: center;
  }
  .mainwrap .cover__img {
    order: -1;
    margin-left: 0;
  }
  .mainwrap .cover__info {
    align-items: center;
  }
  .mainwrap .cover__text {
    padding: 12px 0 60px;
  }
  .mainwrap .cover__subtitle {
    margin-top: 8px;
    font-size: 14px;
  }
  .mainwrap .cover__btn {
    padding-bottom: 12px;
  }
}

.mainwrap .benefit {
  padding: 15px;
  max-width: 320px;
}

@media (max-width: 1199px) {
  .mainwrap .benefit {
    padding: 0;
  }
}

.mainwrap .benefit__head {
  display: flex;
  align-items: flex-end;
}

.mainwrap .benefit__title {
  color: #11192b;
  font-size: 18px;
  font-weight: 700;
  flex: 1 1 auto;
}

.mainwrap .benefit__icon {
  margin-left: 15px;
  flex: 0 0 auto;
  max-width: 60px;
}

.mainwrap .benefit__text {
  margin-top: 12px;
}

.mainwrap .tabs-nav {
  display: flex;
  flex-wrap: wrap;
  margin: 10px -15px;
}

.mainwrap .tabs-nav a {
  color: #a0a3aa;
  font-size: 18px;
  text-decoration: none;
  position: relative;
  display: inline-block;
  margin: 5px 15px;
}

.mainwrap .tabs-nav a::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: -4px;
  height: 2px;
  background-color: #11192b;
  opacity: 0;
  transition: 0.2s;
}

.mainwrap .tabs-nav a.active {
  color: #11192b;
}

.mainwrap .tabs-nav a.active::before {
  opacity: 1;
}

.mainwrap .tabs-nav__mobile-toggle {
  display: none;
}

@media (max-width: 1199px) {
  .mainwrap .tabs-nav {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    white-space: nowrap;
    align-items: flex-start;
    position: relative;
    z-index: 1;
    height: 36px;
    overflow: hidden;
    max-width: 100%;
    margin: 0;
    margin-top: -8px;
  }
  .mainwrap .tabs-nav__mobile-toggle {
    display: block;
    cursor: pointer;
    left: 0;
    right: 0;
    top: 0;
    height: 36px;
    position: absolute;
    z-index: 10;
  }
  .mainwrap .tabs-nav a {
    margin: 8px 0;
  }
  .mainwrap .tabs-nav a.active {
    order: -1;
    padding-right: 40px;
  }
  .mainwrap .tabs-nav a.active:after {
    content: "";
    width: 24px;
    height: 24px;
    position: absolute;
    z-index: 2;
    right: 0;
    top: -2px;
    background-color: transparent;
    background-size: contain;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' %3E%3Cpath fill-rule='evenodd' d='M6.69 7.713a1.26 1.26 0 0 0-1.759-.081l-.01.009c-.265.246-.402.577-.402.92 0 .296.097.594.326.844l6.179 6.857a1.3 1.3 0 0 0 1.931 0l5.996-6.906a1.26 1.26 0 0 0-.084-1.757l-.008-.007c-.5-.444-1.291-.446-1.767.09l-5.111 5.902-5.286-5.865-.005-.005z' fill='%2311192b'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
  }
  .mainwrap .tabs-nav._opened {
    height: auto;
  }
  .mainwrap .tabs-nav._opened a.active:after {
    transform: rotate(180deg);
  }
}

@media (max-width: 719px) {
  .mainwrap .tabs-nav {
    width: 100%;
  }
  .mainwrap .tabs-nav a {
    font-size: 14px;
    line-height: 20px;
    width: 100%;
  }
}

@media (max-width: 1199px) {
  .only-desktop {
    display: none;
  }
}

@media (max-width: 767px) {
  .only-tablet {
    display: none;
  }
}

@media (min-width: 1200px) {
  .only-tablet {
    display: none;
  }
}

@media (min-width: 768px) {
  .only-mobile {
    display: none;
  }
}
