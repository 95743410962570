.header {
	user-select: none;
	position: fixed;
	z-index: 777;
	left: 0;
	right: 0;
	top: 0;
	background-color: #fff;
	border-bottom: 1px solid #eee;

	&__top {
		width: calc(100% + 30px);
		display: flex;
		align-items: center;
		margin: 0 -15px;

		&:before {
			content: "";
			flex: 1 1 auto !important; }

		& > * {
			flex: 0 0 auto;
			margin: 15px; } }

	&__logo {
		order: -1;
		width: 202px;
		height: 54px; }

	&__phone {
		color: #11192b;
		font-size: 24px;
		font-weight: 700;
		text-decoration: none; }

	&__user {}

	&__nav {
		ul {
			padding: 0;
			list-style-type: none;
			display: flex;
			align-items: center;
			margin: 0 -15px; }

		a {
			display: inline-block;
			margin: 15px;
			color: #11192b;
			font-size: 16px;
			text-decoration: none;
			position: relative;

			&:before {
				position: absolute;
				content: "";
				left: 0;
				right: 0;
				top: 100%;
				height: 2px;
				background-color: #458eca;
				opacity: 0;
				transition: 0.2s; }

			@include hover {
				color: #11192b;

				&::before {
					opacity: 1; } }


			&.active {
				color: #458eca;

				&::before {
					opacity: 1; } } } }

	&__btn-menu {
		display: none; }

	@include max(719) {
		height: 48px;

		&__top {
			& > * {
				margin: 8px 10px; } }

		&__logo {
			width: 116px;
			height: 31px; }

		&__btn-menu {
			display: block;
			width: 24px;
			height: 24px;
			cursor: pointer; }

		&__phone {
			font-size: 14px; }
		&__user,
		&__nav {
			display: none; } } }

$animDuration: 0.3s;

& > *:not(.mobile-menu) {
	transform: translateZ(0);
	transform-origin: 0 50vh;
	transition: filter $animDuration, transform $animDuration, opacity $animDuration; }

.mobile-menu--open & {
	& > *:not(.mobile-menu) {
		filter: blur(15px);

		// transform: scale(0.8)
		transform: translate(60px, 0);
		opacity: 0.5;
		transition: $animDuration; }

	.mobile-menu {
		pointer-events: auto;
		transform: translateX(0); } }

.mobile-menu {
	transition: $animDuration;
	transform: translateX(-100%);
	pointer-events: none;
	width: 220px;
	background-color: #ffffff;
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	z-index: 780;
	display: flex;
	flex-direction: column;
	user-select: none;
	padding: 16px;

	&::before {
		content: "";
		position: absolute;
		opacity: 0;
		left: 0;
		width: 100vw;
		top: 0;
		bottom: 0;
		z-index: -1; }

	&__close {
		width: 24px;
		height: 24px;
		align-self: flex-end; }

	& > * {
		flex: 0 0 auto; }

	&:after {
		content: "";
		flex-grow: 1; }

	&__phone {
		color: #11192b;
		font-size: 24px;
		font-weight: 700;
		text-decoration: none;
		order: 11;

		&-text {
			order: 11;
			color: #a0a3aa;
			font-size: 10px; } }

	&__nav {
		margin-bottom: 30px;

		ul {
			padding: 0;
			list-style-type: none; }

		a {
			display: block;
			margin: 20px 0;
			color: #11192b;
			font-size: 14px;
			text-decoration: none;
			position: relative;

			&:before {
				position: absolute;
				content: "";
				left: 0;
				right: 0;
				bottom: -6px;
				height: 2px;
				background-color: #458eca;
				opacity: 0;
				transition: 0.2s; }

			@include hover {
				color: #11192b;

				&::before {
					opacity: 1; } }


			&.active {
				color: #458eca;

				&::before {
					opacity: 1; } } } } }
