.benefit {
	padding: 15px;
	max-width: 320px;

	@include max(1199) {
		padding: 0; }


	&__head {
		display: flex;
		align-items: flex-end; }

	&__title {
		color: #11192b;
		font-size: 18px;
		font-weight: 700;
		flex: 1 1 auto; }

	&__icon {
		margin-left: 15px;
		flex: 0 0 auto;
		max-width: 60px; }

	&__text {
		margin-top: 12px; } }
