@import "ui/vars";
@import "ui/helpers";
@import "ui/grid";

@import "ui/general";
@import "blocks/popup";
@import "ui/typography";
@import "ui/buttons";
@import "ui/form";

.mainwrap {
	@import "ui/header";
	@import "ui/footer";

	// @import ui/grid
	@import "blocks/news";
	@import "blocks/cover";
	@import "blocks/benefit";
	@import "blocks/tabs"; }

.only-desktop {
	@include max(1199) {
		display: none; } }

.only-tablet {
	@include max(767) {
		display: none; }


	@include min(1200) {
		display: none; } }

.only-mobile {
	@include min(768) {
		display: none; } }
