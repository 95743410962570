.btn {
	text-decoration: none !important;
	cursor: pointer;
	max-width: 100%;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	vertical-align: middle;
	outline: none;
	border: none;
	background: none;
	transition: $tr;

	&-fill-blue {
		padding: 10px 30px;
		height: 36px;
		border-radius: 4px;
		font-size: 14px;
		line-height: 16px;
		font-weight: 700;
		background-image: linear-gradient(to right, #46b8e7 0%, #4a6db2 100%);
		color: $white;

		@include hover {
			background-color: #46b8e7; }


		&:active {
			transition: 0.05s;
			background-color: #4a6db2; } }

	&-border-blue {
		padding: 10px 10px;
		height: 36px;
		border-radius: 4px;
		font-size: 14px;
		line-height: 16px;
		border: 1px solid #458ECA;
		color: #458ECA;

		@include hover {
			border-color: #47A6DA;
			color: #47A6DA; }


		&:active {
			border-color: #4A70B4;
			color: #4A70B4; } }

	&-link {
		font-size: 16px;
		line-height: 18px;
		color: #458eca;

		@include hover {
			color: #47A6DA; }


		&:active {
			color: #4A70B4; } } }

.btns {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	margin: -15px;

	& > * {
		margin: 15px; }

	@include max(719) {
		margin: -7px;

		& > * {
			margin: 7px; } } }

[data-toggle],
[data-tab] {
	cursor: pointer; }

.toggle {
	display: flex;

	@include max(719) {
		justify-content: space-between; }


	&__arrow {
		flex: 0 0 auto;
		margin-left: 30px;
		width: 24px;
		height: 24px;
		transition: .4s; }

	&.active .toggle__arrow {
		transform: rotate(180deg); } }
