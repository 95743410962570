.cover {
	background-color: #dfe1e2;

	.container {
		display: flex;
		justify-content: space-between; }

	&__info {
		width: 380px;
		display: flex;
		flex-direction: column;
		flex: 0 1 auto;
		align-items: flex-start;
		max-width: 100%; }

	&__text {
		padding: 60px 0;
		flex: 1 0 auto;
		display: flex;
		justify-content: center;
		flex-direction: column; }

	&__subtitle {
		font-size: 18px;
		margin-top: 24px; }

	&__img {
		flex: 0 0 auto;
		margin-left: 30px;
		line-height: 0; }

	&__btn {
		padding-bottom: 60px; }

	@include max(719) {
		text-align: center;

		.container {
			flex-direction: column;
			align-items: center; }

		&__img {
			order: -1;
			margin-left: 0; }

		&__info {
			align-items: center; }

		&__text {
			padding: 12px 0 60px; }

		&__subtitle {
			margin-top: 8px;
			font-size: 14px; }

		&__btn {
			padding-bottom: 12px; } } }
