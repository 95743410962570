.news {
	margin: 20px 0;

	&__date {
		color: #a0a3aa;
		margin-bottom: 8px; }

	&__link {
		@extend .link; }

	&__title {
		color: #11192b;
		font-size: 18px;
		font-weight: 700;
		line-height: 22.5px; } }
