html,
body {
	margin: 0;
	padding: 0;
	background-color: #fbfeff;
	font-family: Arial;
	font-weight: 400;
	font-size: 14px;
	line-height: normal;
	letter-spacing: normal;
	color: #11192b; }

* {
	box-sizing: border-box; }

img,
svg {
	max-width: 100%;
	max-height: 100%; }

.overflow-hidden {
	overflow: hidden; }

.mainwrap {
	overflow: hidden;
	display: flex;
	flex-direction: column;
	min-height: 100vh;

	& > * {
		flex: 0 0 auto; }

	main {
		flex-grow: 1;
		padding-top: 133px;

		@include max(719) {
			padding-top: 48px; } } }

.container {
	width: 1140px;
	max-width: calc(100% - 90px);
	margin-left: auto;
	margin-right: auto;

	@include max(1199) {
		max-width: calc(100% - 28px); }


	@include max(719) {
		max-width: calc(100% - 24px); } }
