.title-1 {
	color: #11192b;
	font-size: 32px;
	font-weight: 700;
	line-height: 36px;

	@include max(719) {
		font-size: 21px;
		line-height: 24px; } }

.title-2 {
	color: #11192b;
	font-size: 24px;
	font-weight: 700;

	@include max(719) {
		font-size: 16px; } }

.text-left {
	text-align: left; }

.text-center {
	text-align: center; }

.text-right {
	text-align: right; }

.link {
	color: #11192b;

	@include link;
	@include hover {
		color: $green; } }

.text-gray {
	color: #a0a3aa; }

.text-green {
	color: $green; }

.text-blue {
	color: $blue; }

.text-blue-light {
	color: $blue-l; }

.doc-link {
	display: inline-flex;
	max-width: 100%;
	width: 720px;
	color: #11192b;
	font-size: 14px;

	@include link;

	&:after {
		content: "";
		flex-grow: 1; }

	&__description {
		display: flex;
		min-height: 24px;
		align-items: flex-start;

		svg {
			flex: 0 0 auto;
			margin-top: -4px;
			margin-right: 10px; } }

	&__size {
		flex: 0 0 auto;
		margin-left: 15px;
		order: 1;
		color: $green;
		opacity: 0;
		transition: $tr;
		display: flex;
		align-items: center;
		margin-top: -8px;

		@include max(719) {
			display: none; }


		svg {
			margin-right: 10px;
			fill: $green;
			width: 24px;
			height: 24px; } }

	@include hover {
		color: $green;

		.doc-link__size {
			opacity: 1; } } }

.icon-doc {
	width: 24px;
	height: 24px;
	fill: $green; }

.bg-gray {
	background-color: #dfe1e2; }

.fz-11 {
	font-size: 11px; }

.fz-14 {
	font-size: 14px; }
