.footer {
	padding: 30px 0;
	color: #ffffff;
	font-size: 16px;
	background-color: #11192b;

	@include max(719) {
		font-size: 11px; }


	&__row {
		display: flex;
		flex-wrap: wrap;
		margin: 0 -15px; }

	&__col {
		width: calc(50% - 30px);
		margin: 15px;

		@include max(719) {
			width: calc(100% - 30px);
			margin: 8px 15px; }


		&._rights {
			order: 11; } }

	&__link {
		text-decoration: none;
		color: white;
		font-weight: 700;

		@include hover {
			text-decoration: underline; } } }
