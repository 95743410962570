@mixin hover {
	@media (hover: hover) and (pointer: fine) {
		transition: $tr;

		&:hover {
			@content; } } }

@mixin link {
	text-decoration: none;

	@include hover {
		cursor: pointer; } }

@mixin max($w) {
	@media (max-width: $w + px) {
		@content; } }

@mixin min($w) {
	@media (min-width: $w + px) {
		@content; } }

@mixin cover {
	img {
		object-position: 50% 50%;
		object-fit: cover;
		width: 100%;
		height: 100%; } }

@mixin bg {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	z-index: -1;

	@include cover; }
