.tabs-nav {
	display: flex;
	flex-wrap: wrap;
	margin: 10px -15px;

	a {
		color: #a0a3aa;
		font-size: 18px;
		text-decoration: none;
		position: relative;
		display: inline-block;
		margin: 5px 15px;

		&::before {
			content: "";
			position: absolute;
			left: 0;
			right: 0;
			bottom: -4px;
			height: 2px;
			background-color: #11192b;
			opacity: 0;
			transition: $tr; }

		&.active {
			color: #11192b;

			&::before {
				opacity: 1; } } }

	&__mobile-toggle {
		display: none; }

	@include max(1199) {
		display: flex;
		flex-direction: column;
		flex-wrap: nowrap;
		white-space: nowrap;
		align-items: flex-start;
		position: relative;
		z-index: 1;
		height: 36px;
		overflow: hidden;
		max-width: 100%;
		margin: 0;
		margin-top: -8px;

		&__mobile-toggle {
			display: block;
			cursor: pointer;
			left: 0;
			right: 0;
			top: 0;
			height: 36px;
			position: absolute;
			z-index: 10; }

		a {
			margin: 8px 0;

			&.active {
				order: -1;
				padding-right: 40px;

				// &::before
				// 	right: 40px

				&:after {
					content: "";
					width: 24px;
					height: 24px;
					position: absolute;
					z-index: 2;
					right: 0;
					top: -2px;
					background-color: transparent;
					background-size: contain;
					background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' %3E%3Cpath fill-rule='evenodd' d='M6.69 7.713a1.26 1.26 0 0 0-1.759-.081l-.01.009c-.265.246-.402.577-.402.92 0 .296.097.594.326.844l6.179 6.857a1.3 1.3 0 0 0 1.931 0l5.996-6.906a1.26 1.26 0 0 0-.084-1.757l-.008-.007c-.5-.444-1.291-.446-1.767.09l-5.111 5.902-5.286-5.865-.005-.005z' fill='%2311192b'/%3E%3C/svg%3E");
					background-repeat: no-repeat; } } }

		&._opened {
			height: auto;

			a {
				&.active {
					&:after {
						transform: rotate(180deg); } } } } }


	@include max(719) {
		width: 100%;

		a {
			font-size: 14px;
			line-height: 20px;
			width: 100%; } } }
