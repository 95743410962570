.popup {
	position: fixed;
	z-index: 999;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	opacity: 0;
	transition: $tr * 2;
	pointer-events: none;

	&.js--open {
		opacity: 1;
		pointer-events: auto; }

	&__overlay {
		background-color: #11192b;
		opacity: 0.6;
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0; }

	&__inner {
		z-index: 1;
		overflow: hidden;
		width: 560px;
		height: 700px;
		max-height: 100%;
		max-width: 100%;
		border-radius: 4px;
		background-color: #ffffff; }

	&__bg-1 {
		position: absolute;
		z-index: 1;
		top: 0;
		left: 0;
		right: 0;
		overflow: hidden;
		line-height: 0;

		&::before {
			content: "";
			position: absolute;
			left: 0;
			top: 0;
			width: 324px;
			height: 324px;
			background-color: #fff;
			border-radius: 50%;
			transform: translateX(-50%); } }

	&__bg-2 {
		position: absolute;
		z-index: 2;
		left: 0;
		top: 8px;
		line-height: 0; }

	&__close {
		width: 18px;
		height: 18px;
		fill: #11192b;
		right: 38px;
		top: 38px;
		position: absolute;

		@include hover {
			transform: scale(1.15) rotate(90deg); } }

	&__scroll {
		overflow: auto;
		height: 100%;
		padding-top: 180px;
		padding-left: 190px;
		padding-bottom: 30px;
		padding-right: 30px;
		position: relative; }

	&__title {
		color: #11192b;
		font-size: 32px;
		font-weight: 700;
		line-height: 36px; }

	&--open {
		overflow: hidden; }

	@include max(719) {
		&__inner {
			max-width: 360px;
			height: auto;
			position: relative;

			@media(max-height: 600px) {
				min-height: 100%; } }

		&__close {
			right: 10px;
			top: 10px; }

		&__scroll {
			padding: 45px 15px 15px; }

		&__bg-1,
		&__bg-2 {
			display: none; } }


	@include max(360) {
		&__inner {
			border-radius: 0; } } }
